// User Pages

.app-logo {
  height: $logo-height;
  width: $logo-width;
  background: url('../../assets/utils/images/logo-inverse.png');
}

.app-logo-inverse {
  height: $logo-height;
  width: $logo-width;
  background: url('../../assets/utils/images/logo.png');
}

