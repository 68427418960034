.login-left-panel {
  padding: 4%;
}

.Sostos-text {
  height: 45%;
  color: #fff;
  font-weight: 400;
  font-size: 160%;

}

.catos-text {
  color: #000;
  height: 55%;

}

.catos-text-1 {
  text-align: left;
  font-weight: 700;
  font-size: 1.5625rem;
  line-height: 1.9375rem;
  font-family: 'Outfit', sans-serif;
  color: #000;
  padding-bottom: 1rem;
}

.catos-text-2i {
  font-size: 1rem;
  font-weight: 700;
  font-family: 'Outfit', sans-serif;

}



.right-panel {
  padding-top: 6%;
}

.login-text {
  color: #333333;
  font-weight: 800;
  font-size: 120%;
}



.forgot-pass-text {
  display: flex;
  padding: 0%;
  justify-content: right;
}

.forgot-pass-link {
  padding: 0%;
}

.forgot-pass-link-1 {
  padding: 0%;
}

.remember-pass {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 12%;
}

.remember-pass-text {
  color: #808080;
  padding-bottom: 5%;
}


.app-login-box {
  .app-logo {
    margin-bottom: $layout-spacer-lg;
  }


  .login-left-panel {
    padding: 4%;
  }

  .Sostos-text {
    height: 45%;
    color: #fff;
    font-weight: 400;
    font-size: 160%;

  }

  .right-panel {
    padding-top: 6%;
  }

  .login-text {
    color: #333333;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 1.1;
  }

  .login-subtext {
    font-size: 0.75rem;
    font-weight: 400;
    margin: 0.5rem 0;
  }

  .text-primary-link {
    color: #474eb8;
    font-weight: 600;
    font-size: 0.75rem;
    text-decoration: underline !important;
  }

  ;

  .back-text {
    color: #333333;
    font-weight: 400;
    font-size: 0.875rem;
  }

  .forgot-pass-text {
    display: flex;
    padding: 0%;
    justify-content: right;
  }

  .forgot-pass-link {
    padding: 0%;
  }

  .forgot-pass-link-1 {
    padding: 0%;
  }

  .login-button-text {
    background-color: #808080;
  }

  .remember-pass {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 12%;
  }

  .remember-pass-text {
    color: #808080;
    padding-bottom: 5%;
  }
}

.flex-JustifyBetween {
  display: flex;
  justify-content: space-between;
}

.text-primary-link {
  color: #474eb8;
  font-weight: 600;
  font-size: 1rem;
  text-decoration: underline !important;
  cursor: pointer;
}

.text-primary-link[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.error-msg-display {
  color: crimson;
  font-size: 0.75rem;
}

.PrimaryRole-text {
  font-size: 2rem;
  font-weight: bold;
  color: #333333;
}


.regTableData {
  max-height: 30vh;
  overflow-y: auto;
  display: flex;
  flex-flow: wrap;
  gap: 0.5rem;
  margin: 1rem 0rem;
}

.regTableList {
  background-color: #FFFFFF;
  color: #333333;
  font-size: 1rem;
  padding: 8px 16px;
  border-radius: 12px;
  border: 1px solid #D6D6D6;
  font-weight: 600;
}

.regTableListSelected {
  background-color: #4E4E4E;
  color: white;
  font-size: 1rem;
  padding: 8px 16px;
  border-radius: 12px;
  border: 1px solid #D6D6D6;
  font-weight: 600;
}

.Sub-title {
  color: #333333;
  font-weight: bold;
  font-size: 2.5rem;
  width: 500px;
  text-align: left;
}

.subtext-subscription {
  font-size: 1rem;
  font-weight: 400;
  width: 500px;
}

.custom-box {
  background: #f4f4f4;
  border-radius: 8px;
  opacity: 1;
  margin-top: 15px;
  margin-left: 1px;
}

.offer-box {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 1;
  width: 160px;
  height: 55px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 510px;
  margin-top: -80px;
}

.box-container {

  justify-content: space-between;
}

.plan-text {
  text-align: left;
  font: normal normal medium 16px/25px 'Poppins', sans-serif;
  letter-spacing: 0px;
  color: #747474;
  opacity: 1;
  margin-top: 10px;
}

.price-text {
  text-align: left;
  font: normal normal bold 24px/35px 'Poppins', sans-serif;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

.stroke-div {
  border: 1px solid #DDDDDD;
  opacity: 1;
  margin-top: 2px;
}

.plan-features {
  text-align: left;
  font: normal normal medium 14px/21px 'Poppins', sans-serif;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

.Sub-title-subscription {
  font-size: 2rem;
  text-align: left;
  color: #333333;
  font-weight: bold;
}

.Sub-title-subscription-subheader {
  font-size: 1rem;
  color: #333333;
  text-align: left;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.header-container-subscription {
  position: relative;
}

.monthlyYearlyBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 180px;
}

.grid-container-subscription {
  display: grid;
  grid-template-columns: repeat(3, minmax(180px, 1fr));
  gap: 20px;
  overflow-y: auto;
  max-height: 60vh;
  height: 60vh;
  padding: 20px 0px;
}

.grid-item-subscription {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f4f4f4;
  border-radius: 8px;
  padding: 1rem;
  position: relative;
}

.subscription_proceed_btn {
  position: absolute;
  bottom: 1rem;
  max-width: 85%;
  border-radius: 4px;
}

.custom-switch .custom-control-input:checked~.custom-control-label::before,
.custom-switch .custom-control-input:not(:checked)~.custom-control-label::before {
  background-color: #0d6efd;
  /* Blue background color similar to the checked state */
  border-color: #0d6efd;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after,
.custom-switch .custom-control-input:not(:checked)~.custom-control-label::after {
  background-color: #fff;
  transform: translateX(1.25rem);
  /* Position similar to the checked state */
}

.form-switch .form-check-input {
  background-position: left center;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');
}

.custom-switch .form-check-input {
  background-color: #545cd8;
  border-color: #545cd8;
}

.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinners {
  -moz-appearance: textfield;
  appearance: textfield;
}

.box-text {
  margin-left: 5px;
  border: 1px solid #707070;
  border-radius: 10px;
  padding: 0.5rem;
  font-size: 0.75rem;
}

.Recommended_container {
  font-size: 11px;
  background-color: #3E3E3E;
  color: white;
  width: 80%;
  position: absolute;
  top: -3%;
  text-align: center;
  border-radius: 8px;
  padding: 5px 22px;
}

.copyright1 {
  font-size: 1.063rem;
  font-family: "Helvetica Neue", sans-serif;
  padding-top: 14rem;
  color: #707070;
}


.registration-ConatinerDiv {
  min-width: max-content;

  @media (max-width: 768px) {
    min-width: auto;
    width: 100%;
    padding: 8%;
  }

}

.app-login-box {

  @media (max-width: 768px) {
    min-width: auto;
    width: 100%;
    padding: 10%;
  }

}