.header-container {
    padding: 0rem 3.5rem;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: baseline;
    height: auto;
    box-shadow: 0 3px 16px 2px #00000014;
    border-radius: 0px 0px 20px 20px;
    gap: 1rem;

    @media (max-width: 768px) {
        padding: 15px 3.5rem 0;
    }

    @media (max-width: 576px) {
        padding: 15px 15px 0;
    }
}

.header-logo-text {
    font-size: 2.5rem;
    font-weight: bolder;
    color: #2680EA;
}

.header-leftContent {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 1.5rem;
    flex-wrap: wrap;

    @media (max-width: 768px) {
        grid-template-columns: max-content;
        gap: 0rem;
    }
}

.header-rightContent {
    display: flex;
    align-items: center;
    gap: 1rem;
    white-space: nowrap;
    justify-self: end;
}

.header-rightContent-user {
    display: flex;
    gap: 0.5rem;
    background-color: #FAFAFA;
    border-radius: 20px;
    border: 1px solid #C9C9C9;
    padding: 10px;
    align-items: center;
    max-height: 40px;
}

.rightContent-userName {
    font-size: 12px;
    font-weight: 400;
    color: #1A1818;
}

.header-rightContent-userName {
    font-size: 12px;
    font-weight: 400;
    color: #1A1818;

    @media (max-width: 768px) {
        display: none;
    }
}

.header-rightContent-userNameInitial {
    background-color: #717171;
    font-size: 12px;
    color: #FFFFFF;
    border-radius: 50%;
    padding: 2px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    position: relative;
}

.header-rightContent-userNameInitial .crown-icon {
    position: absolute;
    bottom: -4px;
    right: -6px;
    width: 15px;
    height: auto;
}

.header-rightContent-user:hover {
    background-color: transparent;
    color: inherit;
}

.custom-dropdown {
    width: 10px;
}

.Over-Flow-Item {
    padding-left: 8px;
    font-weight: 500;
    color: #1A1818;
}

.header-logo {
    margin-right: 2rem;
}

.AiOutlineBackground {
    background-color: #F2F2F2;
    font-size: 12px;
    border-radius: 50%;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    cursor: pointer;
}

.grid-container-profile {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 10px;
    gap: 10px;
    width: auto;
    max-width: 600px;
    font-size: 1rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
}

.grid-item-profile-header {
    font-weight: bold;
}

.header-search {
    font-weight: normal;
    color: inherit;
    border-bottom: none;
    border-radius: none;
    padding: 0.875rem 2px;

    @media (max-width: 768px) {
        padding: '1rem 2px';
    }
}

.header-search-active {
    font-weight: bold;
    color: #434aad;
    border-bottom: 2px solid #434aad;
    border-radius: 1px;
}