.containerForum {
    display: grid;
    grid-template-columns: 80% 20%;
    gap: 1rem;

    @media (max-width: 768px) {
        grid-template-columns: 100%;
    }
}

.left_columnForumHeader {
    display: grid;
    grid-template-columns: 6fr 2fr 2fr;
    grid-column-gap: 12px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-row-gap: 12px;
    }
}

.left_columnForumbody {
    background-color: rgb(242, 242, 242);
    border-radius: 8px;
    padding: 1rem 1rem 0.2rem;
}

.left_columnForumbodylist {
    display: grid;
    gap: 1rem;
    max-height: 60vh;
    overflow-y: scroll;
}

.right_columnForum {
    @media (max-width: 768px) {
        margin-top: 1rem;
    }
}

.right_columnForumbody {
    font-size: 0.875rem;
    color: #747474;
    font-weight: 400;
    padding: 1rem;
    background: #EFF0FF;
    border-radius: 10px;
}

.right_columnForumHeader {
    font-size: 0.875rem;
    color: #434aad;
    font-weight: 600;
    padding: 0rem 2rem;
    cursor: pointer;
}

.left_columnForumHeaderText {
    font-size: 1.5rem;
    font-weight: bold;
}

.forumDescription_body {
    background-color: rgb(242, 242, 242);
    border-radius: 8px;
    padding: 2rem;
    margin-top: 0.5rem;
}

.forumDescription_body_title {
    font-size: 1.5rem;
    font-weight: bold;
}

.forumDescription_body_subtitle {
    font-size: 0.875rem;
    color: #747474;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: baseline;
        gap: 0.5rem;
    }
}

.forumDescription_body_desc_subtitle {
    font-size: 0.875rem;
    color: #747474;
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
}

.Comments_header {
    font-size: 0.875rem;
    font-weight: bold;
    margin-top: 1rem;
}

.left_columnComment {
    position: relative;
    max-width: 60%;

    @media (max-width: 768px) {
        max-width: 100%;
    }
}

.left_columnComment_searchBtn {
    text-align: right;
}

.comment_nameContainer {
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
    margin-top: 2rem;
}

.commentDesc {
    font-size: 0.75rem;
    margin: 0.5rem 0rem;
}

$colors: (
    #00AFBE,
    #BE8B00,
    #796EFF,
    #008D3B,
    #B54600,
    #BE8B00
);

@mixin randomBackgroundColor {
    $colorCount: length($colors);
    $randomIndex: random($colorCount);
    background-color: nth($colors, $randomIndex);
    // Remove the selected color from the list
    $colors: remove($colors, $randomIndex);
}

.header-userNameInitial {
    background-color: #747474;
    font-size: 12px;
    color: #FFFFFF;
    border-radius: 50%;
    padding: 2px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19px;
    height: 19px;
    position: relative;
    margin-right: 0.2rem;
}

;

@media (max-width: 1072px) and (min-width: 768px) {
    .left_columnForumHeader {
        grid-template-columns: 1fr;
        grid-row-gap: 12px;
    }

    .left_columnForumHeader>div {
        width: 100%;
    }

    .left_columnForumHeader .btn {
        font-size: 0.875rem;
        padding: 0.5rem;
    }
}

@media (max-width: 1072px) {
    .left_columnForumHeader .btn {
        font-size: 0.875rem;
        padding: 0.5rem;
        height: auto;
    }
}

.Guidelines_header {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1.5rem 0px;
}

.Guidelines_subheader {
    font-size: 0.875rem;
    font-weight: 400;
}

.Guidelines_content {
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0.5rem 0px;
}

.Guidelines_content_header {
    font-weight: 600;
}

.content-container {
    width: 100%;
    overflow: hidden;
}

.content-container img {
    max-width: 100%;
    height: auto;
}

.content-container table {
    width: 100%;
    border-collapse: collapse;
}

.content-container th,
.content-container td {
    border: 1px solid black;
    padding: 8px;
}

.content-container table,
.content-container th,
.content-container td {
    box-sizing: border-box;
}

.table-wrapper {
    overflow-x: auto;
}