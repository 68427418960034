* {
    font-family: Poppins, sans-serif;
}


.drug-header {
    font-weight: 700;
    vertical-align: baseline;
    font-size: 1.5rem;
    color: #000000;
    text-decoration: none;
    font-style: normal;
}

.drug-subheader {
    font-size: 1rem;
    color: #707070;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal;
}



.drug-Querysubheader {
    font-size: 0.75rem;
    color: #707070;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal;
}

.drug-content {
    color: #404040;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 1rem;
    font-style: normal;
}

.drug-desc {
    font-size: 1rem;
    color: #707070;
    // font-weight: 400;
    // font-size: 0.75rem;
    // color: #000000;
    // margin: 8px 0px;
    text-align: justify;
}

.drug-subHeaderCPR {
    font-size: 1.25rem;
    color: #000000;
    font-weight: bold;
}

.drug-subContainerCPR {
    margin-top: 1rem;
}

.drug-descHeaderCPR {
    font-size: 1.25rem;
    color: #000000;
    text-decoration: underline;
    font-weight: bold;
}

.CPR-imgConatiner {
    display: flex;
    justify-content: center;
}

.CPR-img {
    background-color: #FFFFFF;
    padding: 0.5rem;
    border-radius: 4px;
    max-width: 50%;
}

.drug-ref {
    vertical-align: super;
    cursor: pointer;
    text-decoration: underline;
    color: #376faa;
    font-size: 0.875rem;
}

// .drugPage-Table {
//     border: 1px solid black;
//     border-collapse: collapse;
//     width: 100%;
// }
.table-container {
    overflow-x: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.table-container .drugPage-Table {
    border-collapse: collapse;
    border: none;
    background-color: rgb(242, 242, 242);
    border-radius: 8px;
    width: 100%;
    min-width: calc(300px * 9);
    /* 9 columns with 300px each */
    table-layout: fixed;
    word-wrap: break-word;
}

.drugPage-Table {
    border-collapse: collapse;
    border: none;
    background-color: rgb(242, 242, 242);
    border-radius: 8px;
    width: 100%;
    margin-top: 1rem;
    margin-right: 0;
    margin-bottom: 1rem;
    margin-left: 0;
    table-layout: fixed;
    word-wrap: break-word;
}

.drugPage-Table td {
    border: 1px solid black;
    vertical-align: top;
}

.drugPage-Table tr:first-child td {
    border-top: none;
}

.drugPage-Table tr:last-child td {
    border-bottom: none;
}

.drugPage-Table tr td:first-child {
    border-left: none;
}

.drugPage-Table tr td:last-child {
    border-right: none;
}

.drugPage-Table th {
    border: 1px solid black;
}

.drugPage-Table tr:first-child th {
    border-top: none;
}

.drugPage-Table tr:last-child th {
    border-bottom: none;
}

.drugPage-Table tr th:first-child {
    border-left: none;
}

.drugPage-Table tr th:last-child {
    border-right: none;
}

.drugPage-Table td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 1rem 0.5rem;
    font-size: 1rem;
    color: #000000;
    font-weight: 400;
}

.drugPage-Table th {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 1rem 0.5rem;
    font-size: 1rem;
    color: #000000;
    font-weight: bold;
}

.drugGroup-body {
    overflow: auto;
    max-height: 37vh;
    color: #333333;

    @media (max-width: 768px) {
        max-height: none;
    }
}

.drugPage-iframe {
    max-height: 80vh;
}

.cancerType-header {
    font-weight: bold;
    font-size: 28px;
    text-align: center;
    color: #333333;
}

.cancerType-subheader {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    margin-top: 4px;
    color: #333333;
}

.drugGroup-button {
    padding: 1rem 2rem;
    box-shadow: 0px -4px 4px #00000014;
    border-radius: 8px;
}

.drugList-header {
    font-size: 1rem;
    text-decoration: underline;
    color: #333333;
    font-weight: bold;
    max-width: max-content
}

.drugList-desc {
    font-size: 0.75rem;
    color: #333333;
    font-weight: 400;

}

.drugPage-container {
    padding: 2rem 2rem 2rem 2rem;
    background: rgb(242, 242, 242);
    border-radius: 8px;
}

.drugPage-img {
    background-color: #FFFFFF;
    padding: 0.5rem;
    border-radius: 4px;
}

.drupPage-subConatiner {
    background-color: #FFFFFF;
    border-radius: 0.5rem;
    padding: 1.5rem 1.5rem 1rem;
    width: 100%;
    margin-top: 1rem;
    break-inside: avoid;
}

.drugPage_geneTableData {
    display: flex;
    flex-flow: wrap;
    gap: 0.5rem;
}

.drugPage_geneTableData_leftHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    // height: 100vh;
}

.drugPage_geneTableList {
    background-color: #4E4E4E;
    color: white;
    font-size: 12px;
    padding: 2px 10px;
    border-radius: 24px;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
}


.cancerPage-noDataFound {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    text-align: justify;
    margin: auto;
    font-size: 1rem;
}

@media only screen and (max-width: 768px) {
    .drugPage-Table {
        display: block;
        overflow-y: auto;
    }

    .drugPage-img {
        max-width: 80%;
    }

}


.CancerTypeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;

}

.RMAcontainer {
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    color: #707070;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal;
}

.text-link {
    text-decoration: underline;
    cursor: pointer;
    color: #434aad;
}

.shareMenuItem {
    width: 100%;
    text-align: left;
    // margin-left: 1rem;
}

.QueryModalFooter {
    display: flex;
    justify-content: center;
    border-top: none;
    padding-top: 0;
    background: none;
}

.QueryModalHeader {
    display: flex;
    justify-content: center;
    background: none;
    border-bottom: none;
}

.QueryModalHeader .btn-close {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
}

.QueryModalTitle {
    font-size: 1.5rem;
    font-weight: bold;
}



// .centered-modal {
//     .modal-dialog {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         min-height: 100vh; // Full screen height
//         margin: 0; // Remove default margin
//     }
// }
.centered-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    width: 70%;
}

.modal-backdrop {
    width: 100%;
    height: 100%;
}

.custom-sweetalert h2 {
    text-align: center;
}


.restrictedSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
}


.card-cancerType {
    height: calc(100vh - 20vh);

    @media (max-width: 768px) {
        height: auto;
    }
}

.card-body-cancerType {
    padding: 1rem 1rem 0rem;

    @media (max-width: 768px) {
        max-height: 66vh;
    }
}

.drug-list-item {
    margin-bottom: 0.5rem;
    height: max-content;
    background-color: rgb(242, 242, 242);
    padding: 1rem;
    border-radius: 0.5rem;
}

.drug-list-item-first-item {
    margin-top: 0;
    border-radius: 0 0.5rem 0.5rem;

}

@media print {
    body {
        visibility: hidden;
    }

    #print-content {
        visibility: visible;
        position: absolute;
        left: 0;
        top: 0;
        padding: 2rem 2rem 2rem 2rem;
        background: rgb(242, 242, 242);
        border-radius: 8px;
    }

    #QueryContent {
        visibility: hidden;
    }

    .drugPage-container {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }
}

.advance-header {
    color: #333333;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
}

.advance-subheader {
    color: #333333;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin-top: 1rem;
}

.advance-paragraph {
    color: #333333;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
}

.advance_table td {
    padding: 0rem 0.5rem;
}

.advance_table tr {
    vertical-align: top;
}


.advance_error {
    font-size: 12px;
    overflow-wrap: break-word;
    max-width: 250px;
    text-align: left;
    color: crimson
}

.auto-expand {
    height: auto;
    overflow-y: hidden;
}

.auto-expand:focus {
    height: auto;
}

.headerButtonCancerList .btn-outline-primary.active {
    color: #545cd8;
    background-color: #fff;
    font-weight: bold;
}

.headerButtonCancerList .btn-outline-primary {
    color: #fff;
    background-color: #545cd8;
    border-radius: 12px 12px 0px 0px;
    font-size: 0.875rem;

    @media (max-width: 576px) {
        font-size: 0.75rem;
        padding: 0.375rem;
    }
}

.headerButtonCancerList {
    @media (max-width: 768px) {
        display: flex;
    }
}

.advance-upload {
    font-size: 0.875rem;
    text-decoration: underline;
    color: grey;
    text-align: left;
}

.cancerPage_pagination .pagination {
    margin: 1rem 0rem;
    display: flex;
    justify-content: center;
    // margin: 0.5rem 0rem;
    padding-left: 0;
    list-style: none;
}

.cancerPage_tabPane {
    overflow-y: auto;
    max-height: 62vh;
    background-color: rgb(255, 255, 255);
    padding: 1rem;
}

.Druglist-headerTitle {
    font-size: 0.75rem;
    color: #434aad;
}

.cancerPage-tabPane-noDataFound {
    height: 62vh;
}

.drug-list-defaultDataConatiner {
    max-height: 67vh;
    overflow-y: auto;
}

.pagination-custom-cancerType {
    margin: 1rem 0rem;
}

.font-weight-bold {
    font-weight: bold;
}

.custom-tooltip .tooltip-inner {
    background-color: white;
    color: #000;
    border: 1px solid #ccc;
    padding: 10px;
    max-width: 400px;
}

.custom-tooltip a {
    text-decoration: underline !important;
}

.tooltip-cell a {
    color: #000000;
}