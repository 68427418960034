.card {
    background-color: #F2F2F2;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    // .d-flex {
    //     flex-direction: column;
    // }

    .right-edit-profile {
        order: 2;
    }
}



.right-edit-profile {
    padding: 5% 2% 5% 5%;
}

/* Profile details styles */
.profile-userNameInitial {
    background-color: #717171;
    font-size: 12px;
    color: #FFFFFF;
    border-radius: 30px;
    padding: 2px 8px;
}

.userNameInitial {
    display: flex;
    align-items: center;
}

.name-profile-details {
    font: normal normal bold 24px/35px Poppins, Arial, sans-serif;
    color: #333333;
    padding: 2% 2% 0% 2%;
}

.plan-profile-details-div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3%;
}

.plan-profile-details {
    background: #BFBFBF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    font: normal normal medium 11px/17px Poppins, Arial, sans-serif;
    padding: 6% 30%;
    width: 230%;
}

.email-section-profile-details {
    font: normal normal medium 11px/17px Poppins, Arial, sans-serif;
    font-size: 11px;
    color: #333333;
    display: flex;
    padding-left: 8%;
}

.password-tab,
.billing-tab {
    height: 5%;
    padding-bottom: 10%;
}

.tabProfileEdit {
    cursor: pointer;
    width: max-content;
}

.tabProfileEdit.active {
    font-weight: bolder;
    color: #434aad;
}

.Profile-details {
    padding-bottom: 8%;
    padding-top: 4%;
}

.forgot-pass-text {
    display: flex;
    padding: 0%;
    justify-content: right;
    font: normal normal normal 12px/18px Poppins, Arial, sans-serif;
    color: #6E6E6E;
    font-size: 12px;
}

.error-msg-display {
    color: crimson;
    font-size: 0.75rem;
}

.input-password {
    width: 50%;
    height: 50%;

    @media (max-width: 768px) {
        width: 100%;
        height: fit-content;

    }
}

.input-password input::placeholder {
    font: normal normal normal 12px/22px Poppins, Arial, sans-serif;
    font-size: 12px;
}

.save-button-div-password {
    display: flex;
    justify-content: right;

    @media (max-width: 768px) {
        display: block;
        width: 100%;
        margin-top: 1rem;
    }
}

.save-button-password {
    width: inherit;
}

.Subscription-div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.current-plan {
    font: normal normal medium 24px/35px Poppins, Arial, sans-serif;
    color: #333333;
    font-size: 24px;
}

.next-payment {
    font: normal normal normal 16px/25px Poppins, Arial, sans-serif;
    color: #717171;
    font-size: 16px;
}

.upgrade-plan {
    padding: 5%;
}

.order-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    font-family: 'Poppins', Arial, sans-serif;
    color: #333333;
}

.order-div {
    padding: 5% 2% 2% 2%;
}

.table-top-text-1,
.table-top-text-2,
.table-top-text-3 {
    font: normal normal medium 14px/21px Poppins, Arial, sans-serif;
    color: #717171;
    font-size: 14px;
    font-weight: 600;
}

.table-top-text-1 {
    width: 50%;
}

.table-top-text-2 {
    width: 35%;
}

.table-top-text-3 {
    width: 15%;
}

.table-bottom-text-1,
.table-bottom-text-2,
.table-bottom-text-3 {
    font: normal normal medium 14px/21px Poppins, Arial, sans-serif;
    color: #717171;
    font-size: 14px;
}

.table-bottom-text-1 {
    width: 50%;
}

.table-bottom-text-2 {
    width: 35%;
}

.table-bottom-text-3 {
    width: 15%;
}

.card-cvv {
    width: 23%;
    margin-right: 3%;
}

.card-number {
    width: 54%;
    margin-right: 3%;
}

.card-details-div {
    width: 65%;
}

.card-holder-name {
    width: 80%;
}

.card-pin {
    width: 49%;
}