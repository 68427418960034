.overflow-hidden-section {
  overflow: hidden;

  @media (max-width: 1080px) {
    overflow: visible;
  }
}

.container-fluid.fixed-top {
  padding-left: 5.625rem;
  padding-top: 1.625rem;
  padding-bottom: 1.625rem;
  background: linear-gradient(to right, white 25%, transparent 75%);

  @media (max-width: 425px) {
    padding-left: 1.625rem;
    }
    
    @media (max-width: 768px) {
    background: white;
  }

}

.catos-title {
  text-align: left;
  font-weight: bold;
  font-size: 1.5625rem;
  /* 25px */
  line-height: 1.9375rem;
  /* 31px */
  font-family: 'Outfit', sans-serif;
  color: #292929;
}

.catos-description {
  font-size: 0.75rem;
  font-weight: 700;
  font-family: "Outfit", sans-serif;
  color: #292929;
}

.underline {
  text-decoration: underline;
}

.hero-section {
  display: flex;
  /* Make the hero section a flex container */
  margin-top: 6.25rem;
  padding-bottom: 6.25rem;
  padding-left: 8.125rem;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-left: 4.125rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding-left: 2.125rem;
  }
}

.content-wrapper {
  width: 45.1875rem;
  height: 7.625rem;
  padding: 8.625rem 0 0;
  position: relative;

  @media (max-width: 1080px) {
    height: auto;
    width: auto;
    padding: 2rem 0 0;
  }

  @media (max-width: 425px) {
    height: auto;
    width: auto;
    padding: 0.5rem 0 0;
  }
}

//   style={{width: "45.1875rem", height: "7.625rem",paddingTop:"8.625rem",paddingBottom:"17.5rem",}}

.heading-container {
  text-align: left;
  font-weight: 500;
  font-size: 2.813rem;
  line-height: 3.8125rem;
  font-family: "Nunito Sans", sans-serif;
  color: #292929;

  @media (max-width: 425px) {
    font-size: 2.125rem;
    line-height: 2.8125rem;
    margin-top: 2rem;
  }
}

//style={{  textAlign: "left", fontWeight: 500, fontSize: "2.813rem", lineHeight: "3.8125rem", fontFamily: "Nunito Sans, sans-serif", color: "#292929" }}

.button-container {
  margin-top: 2.75rem;
}

.get-started-button {
  width: 10.25rem;
  background-color: #5460D1;
  height: 2.75rem;
  font-size: 1rem;
  color: white;
  /* Assuming white text color for the button */
  /* Other button styles from your Button component (if applicable) */
  //style={{width:"10.25rem",background : "#5460D1",height:"2.75rem",fontSize: "1rem"}}
}

.image-container {
  width: 25.375rem;
  height: 25.938rem;
  margin-left: 4.994rem;

  @media (max-width: 1080px) {
    margin-left: 1rem;
  }

  @media (max-width: 768px) {
    margin-top: 3rem;
    margin-left: 7rem;
  }

  @media (max-width: 570px) {
    margin-top: 2rem;
    margin-left: 0;
    width: auto;
    height: auto;
  }

  @media (max-width: 375px) {
    margin-top: 0.5rem;
    margin-left: 0;
    width: auto;
    height: auto;
  }
}

.people-photo {
  width: auto;
  height: auto;

  @media (max-width: 570px) {
    width: 20rem;
    height: 22rem;
  }

  @media (max-width: 375px) {
    width: 15rem;
    height: 17rem;
  }
}

.dot-image {
  position: absolute;
  bottom: -3rem;
  right: 38.5rem;
  z-index: -1;

  @media (max-width: 1078px) {
    right: 23.5rem;
    bottom: 21rem;
  }

  @media (max-width: 768px) {
    right: 37rem;
    bottom: 9.15rem;
  }

  @media (max-width: 425px) {
    right: 17.5rem;
    bottom: 11.5rem;
  }

  @media (max-width: 320px) {
    right: 14rem;
    bottom: 14rem;
  }

  @media (max-width: 375px) {
    right: 14rem;
    bottom: 15.5rem;
  }

  //style={{position:"relative",bottom:"17.5rem",right:"3rem",zIndex:-1}} 
}

.gradient-overlay {
  background: transparent linear-gradient(239deg, #FFFFFF 50%, #EFE8FF 100%) 0% 0% no-repeat padding-box;
  height: 801px;
  width: 723px;
  border-radius: 4rem;
  position: relative;
  // position: absolute;
  bottom: 57rem;
  left: 10rem;
  z-index: -1;
  transform: rotate(24deg);

  @media (max-width: 1080px) {
    display: none;
  }

}

//div-1 finish

.funding-section {
  display: flex;
  background: #B1B8FF;
  // height: 17.188rem;
  height: auto;
  width: 100%;
  align-items: center;
  padding-left: 6.438rem;
  padding-top: 5.813rem;
  padding-bottom: 5.813rem;

  @media (max-width: 1080px) {
    flex-direction: column;
  }

  @media (max-width: 425px) {
    flex-direction: column;
    padding: 2rem 0.5rem;
  }

  @media (max-width: 1240px) {
    padding-left: 2.438rem;
  }
}

.funding-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: auto;

  @media (max-width: 1080px) {
    margin-top: 2rem;
  }

  @media (max-width: 425px) {
    margin-top: 0.5rem;
  }
}

.funding-text-wrapper {
  width: 22.625rem;
  margin-right: 9.5rem;

  @media (max-width: 1390px) {
    margin-right: 3rem;
  }

  @media (max-width: 1200px) {
    margin-right: 1rem;
    width: 17.625rem;
  }

  @media (max-width: 425px) {
    margin-right: 0;
  }

  @media (max-width: 425px) {
    margin-right: 0;
  }

  @media (max-width: 375px) {
    margin-right: 0;
    width: 16.625rem;
  }

}

.funding-title {
  font-weight: bold;
  font-size: 1.875rem;
  font-family: "Nunito Sans", sans-serif;
  color: #000000;
}

.funding-description {
  font-weight: 500;
  font-size: 1.125rem;
  font-family: "Nunito Sans", sans-serif;
  color: #000000;
}


.logo-container {
  width: auto;
  align-items: end;
  display: flex;
  margin-right: 1.875rem;

  @media (max-width: 768px) {
    margin-top: 2rem;
    margin-right: 1.875rem;
    width: calc(25% - 10px);
  }
}

.funding-logo-1 {
  width: 10.25rem;
  height: 6rem;

  @media (max-width: 375px) {
    width: 9rem;
    height: 5rem;
  }
}

.funding-logo-2 {
  width: 6.938rem;
  height: 5.813rem;

  @media (max-width: 768px) {
    position: relative;
    top: 5rem;
  }

}

.funding-logo-3 {
  width: 6.688rem;
  /* Inherit width from the parent container */
  height: 5.875rem;

  @media (max-width: 375px) {
    width: 5.6rem;
    height: 4.5rem;
  }
}

.funding-logo-4 {
  width: 6.125rem;
  /* Inherit width from the parent container */
  height: 6.75rem;

  @media (max-width: 375px) {
    width: 5.125rem;
    height: 5.75rem;
  }
}

.funding-logo-5 {
  width: 7.313rem;
  height: 5.938rem;

  @media (max-width: 425px) {
    width: 6.313rem;
    height: 4.938rem;
  }
}


// div 2 finish

//   .about-us-section {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 36.125rem;
//     padding: 7% 13%;
//     color: #000000;
//     overflow: hidden;
//     position: relative;
//   }

//   .about-us-content {
//     text-align: center;
//   }

//   .about-us-title {
//     font-size: 2.813rem;
//     font-family: "Nunito Sans", sans-serif;
//     font-weight: bold;
//     margin-bottom: 0; /* Remove default margin, if any */
//   }

//   .about-us-description {
//     font-size: 1.125rem;
//     font-family: "Nunito Sans", sans-serif;
//     font-weight: 600;
//     padding-top: 2rem;
//     padding-bottom: 4rem;
//   }

//   .services-container {
//     display: flex;
//     justify-content: center;
//     gap: 6.25rem; 

//   }

//   .service-icon-wrapper {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
//   .service-icon-1{
//     width: 3.438rem;
//     height: 3.375rem;
//     text-align: center;
//   }
//   .service-icon-2{
//     width: 3.188rem;
//     height: 3.5rem;
//     text-align: center;
//   }
//   .service-icon-3{
//     width: 3.063rem;
//     height: 3.625rem;
//     text-align: center;
//   }
//   .service-icon-4{
//     width: 3.25rem;
//     height: 3.625rem;

//   }
//   .service-title {
//     font-size: 1.125rem; /* Adjust font size as needed */
//     font-family: "Nunito Sans", sans-serif;
//     font-weight: bold;
//     text-align: center;
//   }

.about-us-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36.125rem;
  padding: 7% 13%;
  color: #000000;
  overflow: hidden;
  position: relative;
}

.about-us-content {
  text-align: center;
}

.about-us-title {
  font-size: 2.813rem;
  font-family: "Nunito Sans", sans-serif;
  font-weight: bold;
  margin-bottom: 0;
  /* Remove default margin, if any */
}

.about-us-description {
  font-size: 1.125rem;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.services-container {
  display: flex;
  /* Allow items to wrap onto multiple lines */
  justify-content: center;
  gap: 6.25rem;

}

.service-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-icon-1,
.service-icon-2,
.service-icon-3,
.service-icon-4 {
  /* Set a base width for service icons */
  width: 3.25rem;
  height: auto;
  /* Maintain aspect ratio */
}

.service-title {
  font-size: 1.125rem;
  /* Adjust font size as needed */
  font-family: "Nunito Sans", sans-serif;
  font-weight: bold;
  text-align: center;
}

/* Media Queries for Responsiveness */

@media (max-width: 768px) {
  .about-us-section {
    height: auto;
    /* Adjust height as needed for smaller screens */
    padding: 10% 5%;
  }

  .about-us-description {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }

  .services-container {
    flex-wrap: wrap;
    gap: 2.5rem;
  }
}

@media (max-width: 425px) {
  .about-us-title {
    font-size: 2rem;
    /* Adjust font size for even smaller screens */
  }

  .about-us-description {
    font-size: 1rem;
    /* Adjust font size for readability */
  }

  .services-container {
    flex-wrap: wrap;
    gap: 1.5rem;
    flex-direction: column;
  }
}

//   @media (max-width: 320px) {
//     /* Additional adjustments for very narrow screens (optional) */
//     .about-us-content {

//     }
//   }

// div3 finished

.join-us-container {
  display: flex;
  height: 25.375rem;
  background: #E8EAFF;
  position: relative;
  //style={{height:"25.375rem",background:"#E8EAFF",}}
}

.dot-2-image {
  display: flex;
  width: 20%;
  align-items: start;
  justify-content: start;

  @media (max-width: 425px) {
    width: 2%;
  }
}

.ribon-image {
  z-index: 1;
  position: absolute;
  right: 71.313rem;
  top: 5.625rem;
  height: 17.625rem;
  // style={{zIndex:"1",position:'relative',right:"15.313rem",top:"5.625rem",height:"17.625rem"}}
}

.join-us-main {
  width: 60%;
  position: relative;
  right: 1rem;

  @media (max-width: 425px) {
    width: 96%;
    margin-top: 6rem;
  }
}

.join-us-sub {
  //style={{width:"100%",height:"11.396rem",paddingTop:"19%",paddingLeft:"17%",color: "#000000"}}
  width: 100%;
  height: auto;
  padding: 13% 0 0 27%;
  color: #000000;

  @media (max-width: 786px) {
    height: auto;
    padding: 5% 0% 0% 10%;
  }
}

.join-us-title {
  //style={{fontSize:"1.875rem",fontFamily:'Nunito Sans, sans-serif',fontWeight:"bold"}}
  font-size: 1.875rem;
  font-size: "Nunito Sans", sans-serif;
  font-weight: bold;
}

.join-us-des {
  //style={{fontSize:"1.125rem",fontFamily:'Nunito Sans, sans-serif'}}
  font-size: 1.125rem;
  font-size: "Nunito Sans", sans-serif;
}

.dot-3-image {
  display: flex;
  width: 20%;
  align-items: end;
  justify-content: end;

  @media (max-width: 425px) {
    width: 2%;
  }
}

.footer {
  height: 17.625rem;
  padding-top: 4%;

  @media (max-width: 425px) {
    height: auto;
  }
}

.footer-content {
  display: flex;
  justify-content: space-between;

  @media (max-width: 425px) {
    flex-direction: column;
  }
}

.footer-logo-wrapper {
  padding-left: 5rem;

  @media (max-width: 425px) {
    padding-left: 2.5rem;
    padding-bottom: 2.5rem;
  }
}

.footer-logo {
  //width="8.813rem" height="3.375rem"
  width: 8.813rem;
  height: 3.375rem;
}

.footer-details {
  padding-right: 5rem;

  @media (max-width: 425px) {
    padding-left: 2.5rem;

  }
}



.copyright {
  font-size: 1.063rem;
  font-family: "Helvetica Neue", sans-serif;
  color: #707070;
  padding-left: 5rem;
  position: relative;
  top: 7.5rem;

  @media (max-width: 425px) {
    padding-left: 0.5rem;
  }
}